body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 480px) {
  .allcatsubTitle {
    font-size: 10px !important;
    font-weight: 400 !important;
  }

  .mediaAllcatBoxbpd {
    padding: 2px !important;
    margin-top: 15% !important;
  }

  .mediaAllHead {
    font-size: 15px !important;
    font-weight: 500 !important;
    margin-bottom: -1px !important;

  }

  .mobileNewInstallstyleBox {
    padding: 15px !important;
  }

  .mobilemediaview {
    font-size: 11px !important;
    font-weight: 500 !important;
  }

  .doorplusviewicon {
    font-size: 9px !important;
  }

}@media (max-width: 768px) {
  .search-box {
    display: none !important;
  }

  .doorplusheroimg {
    height: 300px !important;
  }

  .doorplusheroimg .doorplusheroheading {
    margin-top: 0 !important;
  }
}

.css-6hq2k4-MuiPaper-root-MuiAccordion-root::before {
  position: absolute;
  display: none !important;
  left: 0;
  top: -1px;
  right: 0;
  height: 1px;
  content: "";
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.12);
  -webkit-transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}