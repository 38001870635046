@media (max-width: 480px) {
  .doorplus-mobile {
    bottom: -49px !important;
    padding: 16px 13px !important;
    width: 88% !important;
    background: rgba(255, 255, 255, 0.826) !important;
    backdrop-filter: blur(10px) !important;
    border: 1px solid rgb(255, 255, 255) !important;
    box-shadow: 0 8px 32px 0 rgba(173, 168, 168, 0.212) !important;
  }

  .doorplusheroimg {
    height: 350px !important;
  }
  .doorplussearchbtn {
    padding: 15px !important;
  }
  .doorplussearchiconmb {
    padding: 5px !important;
    box-shadow: none !important;
  }
  .doorplusheroheading {
    font-size: 24px !important;
    font-weight: 800 !important;
  }
  .doorplusherosubheading {
    font-size: 14px !important;
    font-weight: 100 !important;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .mobile-card {
    height: 350px;
  }
}

@media (min-width: 961px) {
  .mobile-card {
    height: 450px;
  }
}
