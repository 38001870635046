/* body{
    background-color: rgb(234, 241, 255);
} */

.searchinput{
    background-color: rgb(255, 255, 255);
    outline: none !important;
    border: none !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color:  #422afb !important;
    font-size: 12px !important;
}



.css-1aquho2-MuiTabs-indicator {
    background-color:   rgb(198, 215, 253) !important;
 
    
}
.bottomToTop {
    animation: bottomToTopAnimation 1.5s ease-in-out;
  }
  
  @keyframes bottomToTopAnimation {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
  
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 24px;
    margin-left: 30px;
  }
  
  .loader {
    width: 16px;
    height: 16px;
    box-shadow: 0 30px, 0 -30px;
    border-radius: 4px;
    background: currentColor;
    display: block;
    margin: -50px auto 0;
    position: relative;
    color: #422afb;
    transform: translateY(30px);
    box-sizing: border-box;
    animation: animloader 2s ease infinite;
  }
  .loader::after,
  .loader::before {
    content: '';  
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    box-shadow: 0 30px, 0 -30px;
    border-radius: 4px;
    background: currentColor;
    color: #FFF;
    position: absolute;
    left: 30px;
    top: 0;
    animation: animloader 2s 0.2s ease infinite;
  }
  .loader::before {
    animation-delay: 0.4s;
    left: 60px;
  }
  
  @keyframes animloader {
    0% {
      top: 0;
      color:#422afb;
    }
    50% {
      top: 30px;
      color: rgba(255, 255, 255, 0.2);
    }
    100% {
      top: 0;
      color: #422afb;
    }
  }
      
/* ::-webkit-scrollbar {
  width: 9px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
::-webkit-scrollbar-thumb {
  background: #888; 
}
::-webkit-scrollbar-thumb:hover {
  background: #555; 
} */
 
.jodit-workplace+.jodit-status-bar:not(:empty) {
  border-top: 22px solid #ffffff !important;
}

.css-h4y409-MuiList-root {
  list-style: none;
  margin: 0;
  padding: 0px;
  position: relative;
  padding-top: 3px !important;
  padding-bottom: 3px !important;}


