body {
  font-family: sans-serif;
}
h1 ~ p{
  font-size: 20px;
  color: #ccc;
}
.otpInput{
  padding: 11px;
  border-radius: 5px;
}
.otpButton{
  padding: 11px;
    background: #000;
    color: #fff;
    box-shadow: none;
    border: none;
    margin-left: 5px;
    border-radius: 5px;
}
.raiudsStyle {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 300px;
    background-color: rgb(0, 0, 0);
    display: flex;
     font-family: Arial, sans-serif;
      border-radius: 0% 100% 100% 0% / 30% 0% 100% 70%;
      z-index: -1;
}

.Log-sign {
  color: white;
  font-size: 40px;
 padding: 20px;
 margin-left: 29px;


}
.loginContainerStyle {

  width: 450px;
  position: relative;
  padding: 15px;
  background-color: rgb(255, 255, 255);
  text-align: center;
  margin: auto;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  margin-top: 61px;
}

.loginFormStyle {
  margin-top: 40px;
  max-width: 400px;
  margin: 0 auto;
  overflow: hidden;
}

.inputStyle {
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  border: none;
  border-bottom: 2px solid #ccc;
  border-radius: 0;
  position: relative;
}

.inputStyle:focus {
  outline: none;
  border-color: #000000;
}

.inputStyle::placeholder {
  color: #000000;
}

.buttonStyle {
  width: 100%;
  padding: 15px 0;
  background-color: #000000;
  color: white;
  font-size: 18px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;
}

.buttonStyle:hover {
  transform: scale(1.05);
}

/* Auth Links */
.authLinksContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.authLink {
  font-size: 14px;
  margin-top: 10px;
  padding: 8px 12px;
  color: #333333;
  text-decoration: none;
  font-size: 15px;
  font-weight: bolder;
}

.authLink:hover {
  text-decoration: underline;
}

.account {
  font-size: 14px;
  margin-top: 10px;
  padding: 8px 5px;
  color: #333;
}

.resetFormlink {
  text-align: end;
}

@media (max-width: 576px) {
  .loginContainerStyle {
    width: 400px;
    padding: 20px;
    background-color: rgb(255, 255, 255);
    text-align: center;
    margin: auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  .raiudsStyle {
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 0% 100% 100% 0% / 30% 0% 100% 70%;
    background-color: black;
    height: 250px;
    width: 250px;
  }
}

.buttonStyle {
  transition: all 0.3s ease;
}

.buttonStyle:hover {
  transform: scale(1.05);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.loginContainerStyle,
.inputStyle,
.authLinksContainer {
  animation: fadeIn 0.5s ease;
}

.inputStyleContainer {
  position: relative;
}

.password-toggle {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  right: 20px;
  cursor: pointer;
}

.logoImg {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  height: 50px;
}
