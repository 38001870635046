.truncate {
  white-space: nowrap;       /* Prevents text wrapping */
  overflow: hidden;          /* Hides overflowing text */
  text-overflow: ellipsis;   /* Adds "..." at the end */
  width: 250px;              /* Set a fixed width */
}
.price-container {
  display: flex;
  align-items: baseline;
  gap: 10px;
  font-family: Arial, sans-serif;
}

.original-price {
  text-decoration: line-through;
  color: #000;
  font-size:17px;
}

.discount-price {
  color: #000;
  font-weight: normal;
  font-size: 13px;
}

.discount-percent {
  color: green;
  font-size: 13px;
  font-weight: bold;
}

@media (max-width: 600px) {
    .services-mob-style {
      height: 90px !important;
      border-radius: 5% !important;
    }

    .Service-mob-pd{
        padding: 8px !important;
    }
    .cus-bottom-title {
      font-size: 10px !important;
    }
  }
  @media (min-width: 601px) and (max-width: 960px) {
    .services-mob-style {
      height: 350px;
    }
    .cus-bottom-title {
      font-size: 10px !important;
    }
  }
  @media (min-width: 961px) {
    .services-mob-style {
      height: 450px;
    }
  }
  
  backgroundImageContainer{
    background-image: url('../../../../assets//servicesbg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }